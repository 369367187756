<template>
  <div>
    v1.6.7 (Commit 8b497a0)
  </div>
</template>

<script>
export default {
  name: 'ShowVersion',
}
</script>

